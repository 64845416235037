import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { patchRequest } from '../../shared/apiClient'
import NanoDialog from '../../shared/components/NanoDialog'
import SelectDeviceContent from '../../shared/components/SelectDeviceContent'
import { devicePropTypes } from '../device/model'
import { useWorkspaceSettingsStore } from '../../shared/store'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import { useSearchParams } from 'react-router-dom'
import { isDefined, isInteger } from '../../shared/utils/formUtils'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  device: devicePropTypes.isRequired,
}

const defaultProps = {
  isOpen: false,
}

/**
 *
 * @param {PropTypes.InferProps<propTypes>} props
 */
function DeviceModal({ isOpen, onClose, onSuccess, device }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(null)
  const { handleSubmit, control } = useForm()
  const state = useWorkspaceSettingsStore()
  const defaultCapacity = searchParams.get('capacity')
    ? Number(searchParams.get('capacity'))
    : device.capa_max
  const handleClose = () => {
    onClose()
  }
  const onSubmit = (data) => {
    setDisplayError(null)
    setIsLoading(true)
    const payload = {
      device_name: data.device_name,
      device_id: device.device_id,
      device_content_id: data.device_content_id?.id ?? null,
    }
    if (isDefined(data.capa_max) && isInteger(data.capa_max) && data.capa_max !== device.capa_max) {
      payload.capa_max = Number(data.capa_max)
    }

    patchRequest(`v1/devices/${device.device_id}`, payload)
      .then(() => {
        sendEvent('device_updated')
        setIsLoading(false)
        handleClose()
        onSuccess()
      })
      .catch((err) => {
        setDisplayError(err.message)
        setIsLoading(false)
      })
  }

  return (
    <NanoDialog open={isOpen} onClose={handleClose} title={t('device_form_title_update')}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="device_name"
            defaultValue={device.device_name}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('device_form_device_placeholder')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 63 }}
              />
            )}
          />

          <AccessRoleComponent resource="Device" operation={['UPDATE']}>
            <Controller
              control={control}
              defaultValue={String(defaultCapacity)}
              name="capa_max"
              rules={{
                min: { value: 0, message: t('error_must_be_positive_number') },
                required: t('form_field_required_error_message'),
                validate: {
                  isInteger: (minValue) => {
                    if (isDefined(minValue)) {
                      return isInteger(minValue) || t('alert_form_min_or_max_integer_error')
                    }
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  required
                  error={Boolean(error)}
                  helperText={error?.message}
                  label={t('capacity')}
                />
              )}
            />
          </AccessRoleComponent>

          <Controller
            control={control}
            defaultValue={
              device.device_content_id && {
                label: device.deviceContent,
                id: device.device_content_id,
                section: 'device_content',
              }
            }
            name="device_content_id"
            render={({ field, fieldState: { error } }) => (
              <SelectDeviceContent
                error={error}
                onChange={(_, data) => {
                  field.onChange(data)
                }}
                value={field.value}
                label={t('device_content')}
                multiple={false}
                groupFilter={
                  state.getSetting('filterContentByGroup')?.value === 'true'
                    ? device.group_poi.group_poi_id
                    : null
                }
              />
            )}
          />

          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type="submit"
            loadingPosition="start"
            // startIcon={<Icon />} // temporary fix : // forum to fix the error message: https://github.com/mui/material-ui/issues/31235
            fullWidth
          >
            {t('device_form_title_update')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

/**
 * Sync the device modal opened state and parameters with the URL
 *
 * @returns {{
 *   deviceModalIsOpen: boolean,
 *   openDeviceModal: (open: boolean, options?: { capacity?: number }) => void
 * }}
 */
function useDeviceModal() {
  const [searchParams, setSearchParams] = useSearchParams()
  const edit = searchParams.get('edit')
  const [deviceModalIsOpen, setDeviceModalIsOpen] = useState(false)

  useEffect(() => {
    if (edit && !deviceModalIsOpen) {
      setDeviceModalIsOpen(true)
    }
  }, [edit, deviceModalIsOpen])

  const openDeviceModal = useCallback(
    (open, options = {}) => {
      setDeviceModalIsOpen(open)
      if (!open) {
        setSearchParams(
          (prev) => {
            prev.delete('edit')
            prev.delete('capacity')
            return prev
          },
          { replace: true }
        )
      } else {
        setSearchParams((prev) => ({ ...prev, edit: 'true', ...options }), { replace: true })
      }
    },
    [setSearchParams]
  )

  return {
    deviceModalIsOpen,
    openDeviceModal,
  }
}

DeviceModal.defaultProps = defaultProps
DeviceModal.propTypes = propTypes

export default DeviceModal
export { useDeviceModal }
