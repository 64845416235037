import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Stack, TextField, Box, Tooltip } from '@mui/material'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSessionStore, useUserStore } from '../../shared/store'
import { AlertDataType } from './models/alertModel'
import { client, postRequest } from '../../shared/apiClient'
import NanoSelectSingle from '../../shared/components/NanoSelectSingle'
import { LoadingButton } from '@mui/lab'
import SelectUserOrGroupOfUser from '../../shared/components/SelectUserOrGroupOfUser'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import SelectDevicesAndGroups from '../../shared/components/selectDevicesOrGroups/SelectDevicesAndGroups'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import errorMessages from '../../shared/utils/errorMessagesApi'
import { singleDecimalReg } from '../../shared/utils/parseUtils'
import { alertDataTypes } from './AlertFilterDataType'
import { HelpOutline } from '@mui/icons-material'
import { isDefined, isInteger } from '../../shared/utils/formUtils'

const propTypes = {
  // onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({
    alert_id: PropTypes.string.isRequired,
    alert_name: PropTypes.string.isRequired,
    devices_to_check: PropTypes.shape([]),
    device_groups_to_check: PropTypes.shape([]),
    data_type: PropTypes.string.isRequired,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
    recipient_groups_for_notifications_ids: PropTypes.arrayOf(PropTypes.string),
    recipients_for_notifications_ids: PropTypes.arrayOf(PropTypes.string),
    is_active: PropTypes.bool,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      idUser: PropTypes.string.isRequired,
      last_name: PropTypes.string,
      email: PropTypes.string.isRequired,
    })
  ),
  fromRegister: PropTypes.bool,
}

const defaultProps = {
  row: {},
  users: [],
  fromRegister: false,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const AlertForm = ({ onSuccess, row, users, fromRegister }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)
  const { handleSubmit, control, getValues, watch } = useForm()
  const user = useSessionStore((state) => state.user)
  const dataUser = useUserStore((state) => state.dataUser)
  const typeOfAlert = watch('data_type')
  const deviceGroupIds = watch('device_groups_to_check_ids')
  const isUpdateMode = Object.keys(row).length > 0
  const allowDecimals = typeOfAlert === AlertDataType.LevelTons
  const showMaxValue = typeOfAlert !== AlertDataType.RemainingDays
  const showMinValue = Boolean(typeOfAlert)

  // Add current user by default
  let defaultValues = {
    recipients_for_notifications_ids: [
      {
        ...user,
        id: user.idUser,
        label: user?.first_name ? `${user.first_name} ${user.last_name}` : user?.email,
      },
    ],
  }

  // if update of alert
  if (isUpdateMode && users.length) {
    defaultValues = {
      ...row,
      device_groups_to_check_ids: row.device_groups_to_check
        .map((g) => ({
          label: g.group_name,
          id: g.group_id,
          section: 'group',
          ...g,
        }))
        .concat(
          row.devices_to_check.map((d) => ({
            label: d.device_name,
            id: d.device_reference,
            section: 'bin',
            ...d,
          }))
        ),
      recipients_for_notifications_ids: row.recipients_for_notifications_ids.map((r) => {
        const userInfo = dataUser.find((u) => u.idUser === r)
        return {
          label:
            userInfo?.first_name && userInfo?.last_name
              ? `${userInfo.first_name} ${userInfo.last_name}`
              : userInfo?.email,
          id: r,
          section: 'user',
        }
      }),
    }
  } else if (fromRegister && row.device_groups_to_check?.length === 1) {
    defaultValues.device_groups_to_check_ids = row.device_groups_to_check.map((g) => ({
      label: g.group_name,
      id: g.group_id,
      section: 'group',
      ...g,
    }))
  }
  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)
    const payload = {
      alert_name: data.alert_name,
      is_active: row.is_active ?? true,
      data_type: data.data_type,
      device_groups_to_check_ids: data.device_groups_to_check_ids
        .filter((item) => item.section === 'group')
        .map((item) => item.id),
      devices_to_check_ids: data.device_groups_to_check_ids
        .filter((item) => item.section === 'bin')
        .map((item) => item.device_id),
      geofencing_lat: null,
      geofencing_lng: null,
      geofencing_radius: null,
      geofencing_strategy: null,
      min_value: parseFloat(data.min_value),
      max_value: parseFloat(data.max_value) ?? null,
      recipients_for_notifications_ids: (
        data.recipients_for_notifications_ids ?? defaultValues.recipients_for_notifications_ids
      ).map((u) => u.id),
      recipient_groups_for_notifications_ids: [],
    }
    if (row.alert_id) {
      // @FIXME: This logic is dirty; we need to refactor it into the backend to reuse it across apps.
      return Promise.all(
        payload.recipients_for_notifications_ids.map((id, index) => {
          if (index === 0) {
            return client
              .PATCH('/v1/alerts/{id}', {
                params: { path: { id: defaultValues.alert_id } },
                body: { ...payload, recipients_for_notifications_ids: [id] },
              })
              .then(() => {
                sendEvent('alert_updated')
                setIsLoading(false)
                onSuccess()
              })
              .catch((err) => {
                setDisplayAlert(
                  errorMessages(t).find((error) => error.message === err.message)?.code ??
                    t('api_common_error')
                )
                setIsLoading(false)
              })
          } else {
            return client
              .POST('/v1/alerts', {
                body: { ...payload, v2: true, recipients_for_notifications_ids: [id] },
              })
              .then(() => {
                sendEvent('alert_created')
                setIsLoading(false)
                onSuccess()
              })
              .catch((err) => {
                setDisplayAlert(
                  errorMessages(t).find((error) => error.message === err.message)?.code ??
                    t('api_common_error')
                )
                setIsLoading(false)
              })
          }
        })
      )
    } else {
      /** make old alert compatible for v2 */
      return Promise.all(
        payload.recipients_for_notifications_ids.map((id) =>
          postRequest('v1/alerts', {
            ...payload,
            v2: true,
            recipients_for_notifications_ids: [id],
          })
        )
      )
        .then(() => {
          sendEvent('alert_created')
          setIsLoading(false)
          onSuccess()
        })
        .catch((err) => {
          setDisplayAlert(
            errorMessages(t).find((error) => error.message === err.message)?.code ??
              t('api_common_error')
          )
          setIsLoading(false)
        })
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={3}>
        <Controller
          control={control}
          name="alert_name"
          defaultValue={defaultValues.alert_name}
          rules={{ required: t('form_field_required_error_message') }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              label={t('alert_form_name_placeholder')}
              autoFocus
              error={!!error}
              helperText={error?.message}
              required
              inputProps={{ maxLength: 63 }}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={defaultValues.device_groups_to_check_ids ?? []} // FIND THE RIGHT TO DO INIT THAt
          name="device_groups_to_check_ids"
          rules={{ required: t('form_field_required_error_message') }}
          render={({ field, fieldState: { error } }) => (
            <SelectDevicesAndGroups
              label={t('alert_form_select_device_or_group_of_device_placeholder')}
              error={error}
              onChange={(_, data) => {
                field.onChange(data)
              }}
              value={field.value}
              required
              withCombined
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={defaultValues.data_type}
          name="data_type"
          rules={{ required: t('form_field_required_error_message') }}
          render={({ field, fieldState: { error } }) => (
            <NanoSelectSingle
              options={alertDataTypes(t)}
              label={t('alert_form_type_placeholder')}
              variant="outlined"
              placeholder={t('alert_form_type_placeholder')}
              {...field}
              required
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        {showMinValue || defaultValues.min_value ? (
          <Stack direction="row" spacing={2}>
            <Controller
              control={control}
              defaultValue={defaultValues.min_value?.toString()}
              rules={{
                valueAsNumber: true,
                min: { value: 0, message: t('error_must_be_positive_number') },
                validate: {
                  minValueCheck: (minValue) => {
                    const hasMax = isDefined(getValues().max_value)
                    const hasMin = isDefined(minValue)
                    if (!hasMin && !hasMax) return t('alert_form_min_or_max_at_least_error')
                  },
                  isInteger: (minValue) => {
                    if (isDefined(minValue) && !allowDecimals) {
                      return isInteger(minValue) || t('alert_form_min_or_max_integer_error')
                    }
                  },
                  minValueLowerThanMaxValue: (minValue) => {
                    if (isDefined(minValue) && showMaxValue && isDefined(getValues().max_value)) {
                      return (
                        Number(minValue) < Number(getValues().max_value) ||
                        t('alert_form_min_lower_than_max_error')
                      )
                    }
                  },
                  onlyOneComa: (minValue) => {
                    if (isDefined(minValue) && allowDecimals) {
                      return singleDecimalReg.test(minValue) || t('alert_form_one_decimal_error')
                    }
                  },
                },
                deps: ['max_value', 'data_type'],
              }}
              name="min_value"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  InputLabelProps={{
                    // So that the Tooltip works
                    style: { pointerEvents: 'initial' },
                  }}
                  label={
                    <Stack flexDirection="row" alignItems="center" gap={1}>
                      {t('min_value')}
                      <Tooltip title={t('alert_min_hint')} placement="top">
                        <HelpOutline fontSize="small" color="action" />
                      </Tooltip>
                    </Stack>
                  }
                  type="number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            {showMaxValue || defaultValues.max_value ? (
              <Controller
                defaultValue={defaultValues.max_value?.toString()}
                control={control}
                rules={{
                  valueAsNumber: true,
                  min: { value: 0, message: t('error_must_be_positive_number') },
                  validate: {
                    maxValueCheck: (maxValue) => {
                      const hasMin = isDefined(getValues().min_value)
                      const hasMax = isDefined(maxValue)
                      if (!hasMin && !hasMax) return t('alert_form_min_or_max_at_least_error')
                    },
                    isInteger: (maxValue) => {
                      if (isDefined(maxValue) && !allowDecimals) {
                        return isInteger(maxValue) || t('alert_form_min_or_max_integer_error')
                      }
                    },
                    // No need to check for min < max, because it is already checked in min_value
                    onlyOneComa: (maxValue) => {
                      if (isDefined(maxValue) && allowDecimals) {
                        return singleDecimalReg.test(maxValue) || t('alert_form_one_decimal_error')
                      }
                    },
                  },
                  deps: ['min_value', 'data_type'],
                }}
                name="max_value"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="number"
                    InputLabelProps={{
                      // So that the Tooltip works
                      style: { pointerEvents: 'initial' },
                    }}
                    label={
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        {t('max_value')}
                        <Tooltip title={t('alert_max_hint')} placement="top">
                          <HelpOutline fontSize="small" color="action" />
                        </Tooltip>
                      </Stack>
                    }
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            ) : null}
          </Stack>
        ) : null}

        {!fromRegister && (
          <AccessRoleComponent resource="User" operation={['CREATE', 'UPDATE']}>
            <Controller
              control={control}
              name="recipients_for_notifications_ids"
              defaultValue={defaultValues.recipients_for_notifications_ids}
              rules={{ required: t('form_field_required_error_message') }}
              render={({ field, fieldState: { error } }) => (
                <SelectUserOrGroupOfUser
                  error={error}
                  value={field.value}
                  required
                  filterAccessGroups={
                    deviceGroupIds?.map((dg) =>
                      dg.section === 'group' ? dg.group_id : dg.farm_id
                    ) ?? []
                  }
                  onChange={(_, data) => {
                    field.onChange(data)
                  }}
                />
              )}
            />
          </AccessRoleComponent>
        )}

        {!!displayError && <Alert severity="error">{displayError}</Alert>}

        <LoadingButton
          loading={isLoading}
          type="submit"
          loadingPosition="start"
          // startIcon={<Icon />} // temporary fix : // forum to fix the error message: https://github.com/mui/material-ui/issues/31235
          fullWidth
        >
          {row.alert_id ? t('alert_form_submit_button_title_update') : t('alerts_button_title')}
        </LoadingButton>
      </Stack>
    </Box>
  )
}

AlertForm.defaultProps = defaultProps
AlertForm.propTypes = propTypes

export default AlertForm
