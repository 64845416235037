/**
 * Combines multiple React Hook Form validate functions into a single validate function.
 *
 * @param validates Array of validate functions, ignoring nullish values for convenience
 * @returns A validator for the combined validate functions
 * 
 * Example: 
 * combineValidates(
        isActiveStep && validateRequired,
        isActiveStep &&
          (() => {
            return !isMissingDeviceContents || t('missing_infos_selected_silos');
          })
 */
function combineValidates(
  // destructure the array of arguments
  ...validates
) {
  return (value) => {
    for (const validate of validates) {
      if (!validate) continue

      const result = validate(value)
      if (result !== true) {
        return result
      }
    }
    return true
  }
}

/**
 * @returns True if the value in an form input or select is not empty
 */
function isDefined(value) {
  return Boolean(value?.length > 0)
}

/**
 * @returns True if the value of an input is an integer
 */
function isInteger(value) {
  return Number.isInteger(Number(value))
}

export { isDefined, isInteger, combineValidates }
